// 系统日志接口
import {
	axios
} from '../../utils/http.js'

const DevApi = {
	SystemList: '/v3/System/LogsList',//系统日志列表
	SystemLogsClass: '/v3/System/LogsClass',//系统日志select
}
//系统日志列表
export function SystemList(data) {
	return axios({
		url: DevApi.SystemList,
		method: 'post',
		data:data
		
	})
}
//系统日志select
export function SystemLogsClass(data) {
	return axios({
		url: DevApi.SystemLogsClass,
		method: 'post',
		data:data
	})
}
