<template>
	<div class="logs">
		<div class="logs-T">
			<div class="logs-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="logs">
					<div class="logs1">
						<inputs @selects="selectsValue" :selectShow="true" selectPlace="请选择分类" :options="options"></inputs>
					</div>
					<div class="userManagement-tit-mai1-btn"><!-- @click="clickNew()" -->
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="logs-TR">
				<div class="logs-searchsMai">
					
				</div>
			</div>
		</div>
		<div class="logs-B">
			<div class="logs2">
				<el-table :data="tableData" border style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="Id" label="编号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_class" label="分类" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_Title" label="标题" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_Txt" label="详情" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="时间" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.CreateTime | formatDate(scope.row.CreateTime)}}
						</template>
					</el-table-column>
					
				</el-table>
			</div>
			
			<div class="logs-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				 :current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			
		</div>
	</div>
</template>
<script>
	import {
		SystemList,
		SystemLogsClass
	} from '../../api/systemManagement/logs.js'
	import {formatDate} from '../../utils/Times.js'
	export default {
		data() {
			return {
				total:0,
				pages:{ 
					T_class:'',
					page:1,
					page_z:10
				},
				loading: false,
				region:'所有分类',
				multipleSelection: [],
				options: [],
				tableData: []
			}
		},
		created() {
			this.GetSystemLogsClassApi()
		},
		mounted() {
			this.GetSystemListApi()
		},
		filters:{
			 formatDate(time) {
			    let date = new Date(time);
			    return formatDate(date);
			},
		},
		methods: {
			GetSystemListApi(){
				SystemList(this.pages).then(res=>{
					////console.log('sssd',res)
					if(res.data.Code===200 && res.data.Msg==='ok!'){
						this.total = res.data.Data.Num
						res.data.Data.Data.length==0?this.tableData = []:this.tableData = res.data.Data.Data
					}
				})
			},
			GetSystemLogsClassApi(){
				var that = this
				SystemLogsClass({}).then(res=>{
					if(res.data.Code===200 && res.data.Msg==='ok!'){
						var Arr = res.data.Data
						if(Arr!=null){
							Arr.forEach(function(item){
								var obj = {
									value: '',
									label: ''
								}
								obj.value = item
								obj.label = item
								that.options.push(obj)
							})
							that.options.unshift({
								value: '',
								label: '所有分类'
							})
						}
					}
				})
			},
			selectsValue(e){
				if(e==='所有分类'){
					this.pages.T_class = ''
				}else{
					this.pages.T_class = JSON.stringify(e)
				}
				this.pages.page = 1
				this.GetSystemListApi()
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetSystemListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetSystemListApi()
			},
		}
	}
</script>

<style lang="scss">
	.el-tooltip__popper{
	  max-width:400px;
	}
	.logs {
		user-select: none;
		.logs-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;
			.logs {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;
				.userManagement-tit-mai1-btn{
				}
				.logs1{
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.logs-TL {
				display: flex;
				align-items: center;
			}

			.logs-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;

				.logs-searchsMai1 {
					height: 30px;
					display: flex;
					align-items: center;
					color: #F56C6C;
					border-radius: 30px;
					background: #F56C6C;
					color: #fff;
					padding: 0 10px;
					font-size: 12px;
					border: 1px solid #ea6868;
				}

				.logs-searchsMai1q {
					background: #e6a23c;
					border: 1px solid #e6a23c;
					margin-right: 10px;
				}
			}
		}
		
		.logs-B{
			background: rgba(#fff, 1);
			margin: 10px;
			min-height: 600px;
			.logs2 {
				// border-radius: 10px;
				overflow: hidden;
				margin: 10px;
				padding: 20px;
			}
			
			.logs-pagination {
				padding:50px 20px;
				margin: 10px;
				border-radius: 2px;
				display: flex;
			}
		}
	}
</style>
